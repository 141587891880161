/**
 * @file   src\containers\ResetPassword.tsx
 * @brief  Reset Password Page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button } from 'react-bootstrap';
import '../assets/styles/prelogin.scss';
import { toast } from 'react-toastify'
import Strings from '../assets/strings/Strings.json';
import Logo from '../assets/img/logo-prelogin.svg';
import NBInputGroup from '../components/NBInputGroup';
import ForgotPassIc from '../assets/img/icons/ForgotPass';
import { passwordValidation } from '../utils/authHelper';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { resetPassword } from '../store/actions/authAction';
import { resetPasswordResponseState } from '../store/slices/authSlice';
import { IResetPassword, IResetPasswordError } from '../interfaces/GeneralInterface';
import NBInputGroupPswd from '../components/NBInputGroupPswd'

const ResetPassword = () => {
  const dispatch = useAppDispatch();
  const naviagte = useNavigate();
  const { id } = useParams();

  const ResetPasswordResponse = useAppSelector((RootReducer) => RootReducer.auth.resetpassword);
  const [passwordsError, setPasswordsError] = useState<IResetPasswordError>({
    new_passwordError: '',
    confirm_passwordError: '',
  });
  const [passowrd, setPassword] = useState<IResetPassword>({
    newPass: '',
    rePass: '',
  });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showPasswordNew, setShowPasswordNew] = useState<boolean>(false);
  // handle reset password response
  useEffect(() => {
    if (ResetPasswordResponse && ResetPasswordResponse.isSuccess) {
      if (ResetPasswordResponse && ResetPasswordResponse.errorCode === 1000) {
        toast.success(ResetPasswordResponse.errorMessage);
        naviagte('/signin');
      } else if (ResetPasswordResponse && ResetPasswordResponse.errorCode !== 1000) {
        toast.error(ResetPasswordResponse.errorMessage);
      }
      dispatch<any>(resetPasswordResponseState());
    }
  }, [ResetPasswordResponse]);

  // handle password onchange data
  const handleChanges = (event: { target: { name: any; value: string } }) => {
    setPassword((passowrd: any) => ({
      ...passowrd,
      [event.target.name]: event.target.value,
    }));
  };

  // handle submit button and calls reset password api
  const submit = () => {
    let isValid = validation();
    if (isValid) {
      const payload ={
        token: id,
        new_password: passowrd.newPass,
        confirm_password: passowrd.rePass,
      }
      dispatch<any>(resetPassword(payload));
    }
  };

  // handle data error validations.
  const validation = () => {
    let isValid = true;
    setPasswordsError({
      new_passwordError: '',
      confirm_passwordError: '',
    });
    const passwordErrorMsg = passwordValidation(passowrd.newPass, 'the New Password');
    if (passwordErrorMsg !== '') {
      isValid = false;
      setPasswordsError((passwordsError) => ({
        ...passwordsError,
        ["new_passwordError"]: passwordErrorMsg,
      }));
    }
    if (passowrd.rePass === '') {
      isValid = false;
      setPasswordsError((passwordsError) => ({
        ...passwordsError,
        ["confirm_passwordError"]: 'Enter Confirm New Password.',
      }));
    } else if (passowrd.newPass !== passowrd.rePass) {
      isValid = false;
      setPasswordsError((passwordsError) => ({
        ...passwordsError,
        ["confirm_passwordError"]: 'New Password and re-entered Confirm New Password does not match.',
      }));
    }
    return isValid;
  };

  // handle show password and new password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  }
  const handleShowPasswordNew = () => {
    setShowPasswordNew(!showPasswordNew);
  };
  const submitFormOnKeyDown = (event: any) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      submit();
    }
  }

  return (
    <Container fluid className='pre-login'>
      <Row>
        <Col lg='6'>
          <div className='nb-caption d-flex align-items-center justify-content-center'>
            <ForgotPassIc />
          </div>
        </Col>
        <Col lg='6' className='d-flex align-items-center justify-content-center'>
          <div className='nb-login'>
            <img src={Logo} alt='' width={116} />
            <h1>{Strings.ResetPassword.Title}</h1>
            <NBInputGroupPswd
              showpass={showPassword}
              onKeyDown={submitFormOnKeyDown}
              handleShowPassword={handleShowPassword}
              value={passowrd?.newPass}
              onChange={handleChanges}
              error={passwordsError?.new_passwordError}
              id="newPass"
              name="newPass"
              type="password"
              label={Strings.ResetPassword.Label1}
            />
            <NBInputGroupPswd
              showpass={showPasswordNew}
              onKeyDown={submitFormOnKeyDown}
              handleShowPassword={handleShowPasswordNew}
              value={passowrd?.rePass}
              onChange={handleChanges}
              error={passwordsError?.confirm_passwordError}
              id="rePass"
              name="rePass"
              type="password"
              label={Strings.ResetPassword.Label2}
            />
            <Button onClick={submit} variant="primary" className="w-100">
              {Strings.ResetPassword.Button}
            </Button>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ResetPassword;
