
const LogoInner = (props: any) => {
  const pathName = window.location.pathname;

  return (
    <svg onClick={() => {pathName !== '/support' && pathName !== '/deleteaccount' && props.navigate('/')}}  width="157" height="41" viewBox="0 0 157 41" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_54_32)">
        <path className="secondary-col" d="M45.6125 21.403C46.9879 21.3937 48.3542 21.627 49.6484 22.0921C49.9709 22.1945 50.2791 22.337 50.5659 22.5164L49.0273 25.3035C48.6854 25.0759 48.3189 24.8875 47.9348 24.7421C47.3694 24.5131 46.7638 24.4 46.1538 24.4095C45.7184 24.3957 45.2848 24.4718 44.8802 24.633C44.7304 24.6812 44.5998 24.7756 44.5071 24.9027C44.4144 25.0297 44.3644 25.1828 44.3642 25.3401C44.3731 25.5092 44.4321 25.6719 44.5337 25.8075C44.6352 25.9431 44.7749 26.0455 44.9348 26.1017C45.4602 26.3395 46.0143 26.5081 46.5832 26.6032C47.2344 26.7405 47.8705 26.941 48.4827 27.2019C49.1088 27.4598 49.6622 27.867 50.0945 28.3878C50.5533 28.9864 50.7832 29.7289 50.743 30.4818C50.7719 31.2579 50.5683 32.0249 50.1584 32.6848C49.7741 33.2579 49.2489 33.7228 48.6331 34.0351C47.9862 34.3631 47.2936 34.5922 46.5786 34.7149C45.8687 34.8414 45.1492 34.9071 44.4281 34.9111C43.5744 34.9219 42.7224 34.8326 41.8896 34.645C41.2863 34.5089 40.6998 34.307 40.1406 34.0431C39.8149 33.889 39.5031 33.7075 39.2085 33.5003L40.7291 30.3893C41.1347 30.7008 41.5781 30.9598 42.0487 31.1602C42.6594 31.443 43.3249 31.5879 43.998 31.5846C44.5272 31.6154 45.0557 31.5151 45.5366 31.2926C45.6937 31.2275 45.8286 31.1184 45.925 30.9784C46.0214 30.8384 46.0752 30.6736 46.0799 30.5037C46.084 30.389 46.0629 30.2748 46.0179 30.1691C45.9729 30.0635 45.9052 29.969 45.8196 29.8924C45.6005 29.7072 45.3455 29.5691 45.0706 29.4867C44.6878 29.3601 44.2973 29.2581 43.9015 29.1814C43.4508 29.0813 43.0082 28.9479 42.5773 28.7823C42.1056 28.6073 41.6613 28.366 41.2577 28.0659C40.8092 27.7404 40.4476 27.3097 40.2047 26.8118C39.9618 26.3138 39.8452 25.7639 39.8649 25.2104C39.854 24.8217 39.9206 24.4348 40.061 24.0722C40.2014 23.7096 40.4127 23.3786 40.6825 23.0985C41.2771 22.5065 42.009 22.0707 42.813 21.83C43.717 21.5428 44.6606 21.3996 45.6092 21.4056L45.6125 21.403Z" fill="white" />
        <path className="secondary-col" d="M60.5698 21.403C61.4708 21.4027 62.3624 21.5858 63.1902 21.9411C64.0401 22.3047 64.7687 22.9028 65.2907 23.6652C65.878 24.5833 66.1667 25.6604 66.117 26.7489V34.5359H61.3753V27.5291C61.4292 26.791 61.2135 26.0585 60.7682 25.4671C60.5604 25.2341 60.3031 25.0505 60.0151 24.9298C59.7271 24.809 59.4157 24.7542 59.1037 24.7694C58.6314 24.7711 58.1682 24.8985 57.7615 25.1385C57.3347 25.3892 56.9769 25.7419 56.7203 26.1649C56.4489 26.6246 56.3106 27.1505 56.3208 27.6841V34.5353H51.5752V13.1509H56.3188V23.6519C56.4801 23.2447 56.7483 22.8886 57.0951 22.6209C57.5446 22.2452 58.0559 21.9503 58.6064 21.7495C59.2338 21.5139 59.8995 21.3964 60.5698 21.403Z" fill="white" />
        <path className="secondary-col" d="M73.2674 31.0192C73.76 31.0325 74.2466 30.9093 74.6735 30.6634C75.0684 30.4216 75.3855 30.0716 75.5869 29.655C75.8085 29.1933 75.9188 28.6862 75.9092 28.1743C75.918 27.6601 75.807 27.151 75.5849 26.687C75.3827 26.2662 75.0662 25.9105 74.6715 25.6607C74.2476 25.4064 73.7597 25.2783 73.2654 25.2915C72.7683 25.2794 72.2777 25.4073 71.85 25.6607C71.4509 25.9109 71.1285 26.266 70.9179 26.687C70.6908 27.1495 70.5768 27.6592 70.585 28.1743C70.574 28.6882 70.6882 29.197 70.9179 29.657C71.1249 30.0757 71.4486 30.4259 71.85 30.6654C72.2815 30.9098 72.7715 31.0321 73.2674 31.0192ZM73.2674 34.9104C71.9746 34.9338 70.6961 34.6367 69.5464 34.0457C68.474 33.4955 67.5784 32.6546 66.9622 31.6194C66.3461 30.5841 66.0342 29.3964 66.0624 28.1923C66.0376 26.9811 66.3503 25.7868 66.9655 24.7428C67.5807 23.6988 68.4743 22.8463 69.5464 22.2803C70.6925 21.6778 71.9726 21.3748 73.2674 21.3996C74.5553 21.3725 75.8286 21.6757 76.9657 22.2803C78.0071 22.8456 78.8751 23.6829 79.477 24.7028C80.0912 25.7621 80.4054 26.9683 80.3858 28.1923C80.4089 29.411 80.0944 30.6124 79.477 31.6638C78.8745 32.6727 78.0057 33.4966 76.9657 34.0451C75.8249 34.6381 74.5532 34.9361 73.2674 34.9104Z" fill="white" />
        <path className="secondary-col" d="M92.5853 28.163C92.593 27.6168 92.4593 27.0779 92.1972 26.5986C91.9485 26.1498 91.5865 25.774 91.1473 25.5083C90.7006 25.2413 90.1885 25.1031 89.6679 25.1093C89.1309 25.1028 88.6021 25.2406 88.1367 25.5083C87.6911 25.771 87.3235 26.1472 87.0714 26.5986C86.8137 27.0783 86.6788 27.6142 86.6788 28.1587C86.6788 28.7031 86.8137 29.2391 87.0714 29.7188C87.3224 30.1698 87.6904 30.5448 88.1367 30.8044C88.6032 31.0687 89.1317 31.2042 89.6679 31.1968C90.188 31.206 90.7003 31.0701 91.1473 30.8044C91.5869 30.5453 91.9493 30.1738 92.1972 29.7281C92.4608 29.2491 92.5946 28.7096 92.5853 28.163ZM86.6267 41.0007H81.9517V21.7774H86.6786V23.6399C86.8918 23.2691 87.1757 22.9437 87.5142 22.6821C87.9845 22.298 88.5152 21.9944 89.0847 21.7834C89.7804 21.5218 90.5192 21.3934 91.2625 21.405C92.3948 21.3751 93.509 21.6937 94.4542 22.3176C95.3469 22.9316 96.0555 23.7769 96.5041 24.7627C96.9924 25.83 97.2373 26.9923 97.2211 28.1657C97.2355 29.3432 96.9744 30.5077 96.4588 31.5667C95.9758 32.5552 95.2364 33.3963 94.3177 34.0025C93.3537 34.6214 92.2263 34.9376 91.0807 34.9104C90.2922 34.9316 89.5094 34.7709 88.7931 34.4408C88.2384 34.1808 87.7356 33.8222 87.3091 33.3826C87.0375 33.114 86.8095 32.8048 86.6334 32.466V41L86.6267 41.0007Z" fill="white" />
        <path className="primary-col" d="M96.7039 21.7774H101.764L104.85 30.253L107.942 21.7774H112.978L107.626 34.5366H102.088L96.7039 21.7774Z" fill="white" />
        <path className="primary-col" d="M115.866 28.163C115.858 28.713 115.998 29.2551 116.272 29.7321C116.532 30.1824 116.907 30.5556 117.359 30.8137C117.81 31.0717 118.322 31.2053 118.843 31.2008C119.374 31.2069 119.897 31.0715 120.359 30.8084C120.795 30.5453 121.153 30.1705 121.395 29.7228C121.652 29.2433 121.782 28.7066 121.774 28.163C121.784 27.6192 121.654 27.0819 121.395 26.6032C121.203 26.2648 120.945 25.9679 120.637 25.7295C120.329 25.4911 119.977 25.3159 119.601 25.2141C119.225 25.1124 118.832 25.086 118.446 25.1364C118.06 25.1869 117.687 25.3133 117.35 25.5083C116.902 25.7715 116.53 26.1474 116.273 26.5985C115.998 27.0738 115.858 27.6144 115.866 28.163ZM121.829 34.5359V32.4646C121.645 32.8048 121.408 33.113 121.126 33.3772C120.691 33.8117 120.187 34.1695 119.633 34.4355C118.95 34.7648 118.199 34.9273 117.441 34.9098C116.29 34.9351 115.157 34.6191 114.186 34.0018C113.254 33.3999 112.502 32.5585 112.008 31.566C111.497 30.5042 111.232 29.3411 111.232 28.163C111.232 26.9849 111.497 25.8218 112.008 24.76C112.506 23.7676 113.257 22.9241 114.186 22.3149C115.157 21.6967 116.29 21.3792 117.441 21.4023C118.163 21.3862 118.881 21.5165 119.55 21.7854C120.086 22.001 120.58 22.3065 121.012 22.6887C121.325 22.9553 121.586 23.2768 121.783 23.6372V21.7748H126.482V34.5359H121.829Z" fill="white" />
        <path className="primary-col" d="M136.394 21.403C137.295 21.4027 138.187 21.5858 139.015 21.9411C139.865 22.3047 140.593 22.9028 141.115 23.6652C141.705 24.5824 141.993 25.6602 141.942 26.7489V34.5359H137.202V27.5291C137.256 26.7837 137.022 26.0459 136.549 25.4671C136.33 25.2352 136.064 25.0529 135.768 24.9326C135.473 24.8122 135.155 24.7565 134.836 24.7693C134.371 24.7688 133.914 24.8965 133.517 25.1385C133.104 25.391 132.761 25.7441 132.522 26.1649C132.263 26.6289 132.132 27.1531 132.143 27.6841V34.5353H127.416V21.7768H132.143V23.6512C132.304 23.2441 132.572 22.8879 132.919 22.6202C133.37 22.2445 133.883 21.9497 134.435 21.7488C135.061 21.5137 135.725 21.3962 136.394 21.4023L136.394 21.403Z" fill="white" />
        <path className="primary-col" d="M146.389 28.163C146.378 28.7133 146.519 29.2559 146.795 29.7321C147.2 30.4085 147.854 30.8998 148.617 31.1009C149.379 31.3021 150.191 31.197 150.877 30.8084C151.314 30.5466 151.672 30.1715 151.914 29.7228C152.163 29.2404 152.293 28.7055 152.293 28.1627C152.293 27.6199 152.163 27.085 151.914 26.6025C151.671 26.1535 151.313 25.7771 150.877 25.5123C150.42 25.25 149.902 25.1116 149.375 25.1108C148.848 25.11 148.33 25.2468 147.872 25.5077C147.424 25.7709 147.053 26.1467 146.795 26.5979C146.521 27.0734 146.381 27.6143 146.389 28.163ZM152.352 34.5359V32.4646C152.168 32.8048 151.931 33.113 151.649 33.3772C151.214 33.8127 150.707 34.1706 150.151 34.4355C149.468 34.7643 148.717 34.9268 147.96 34.9098C146.808 34.9344 145.676 34.6185 144.704 34.0018C143.772 33.3999 143.02 32.5586 142.526 31.566C142.015 30.5042 141.75 29.3411 141.75 28.163C141.75 26.9849 142.015 25.8218 142.526 24.76C143.024 23.7676 143.775 22.9241 144.704 22.3149C145.675 21.6967 146.808 21.3792 147.96 21.4023C148.681 21.3862 149.399 21.5165 150.069 21.7854C150.604 22.001 151.098 22.3065 151.53 22.6887C151.843 22.9553 152.104 23.2768 152.302 23.6372V21.7748H157V34.5359H152.349H152.352Z" fill="white" />
        <path className="primary-col" d="M28.3172 13.1237H22.9338C22.7002 13.4595 22.3945 13.739 22.039 13.9418C21.5082 14.2409 20.9574 14.5031 20.3906 14.7267C20.0733 15.0381 19.6877 15.2711 19.2645 15.4074C18.8412 15.5436 18.392 15.5793 17.9525 15.5116C17.719 15.5367 17.4841 15.5458 17.2494 15.5389C16.4486 15.6324 15.6396 15.6324 14.8387 15.5389C13.5324 15.555 12.2505 15.1847 11.1543 14.4746C10.8136 14.2462 10.5219 13.9522 10.2961 13.6099C10.2595 13.578 10.2229 13.5554 10.1863 13.5234C10.0512 13.4019 9.92754 13.2683 9.81679 13.1243H4.94934C3.63759 13.1254 2.37983 13.6462 1.45197 14.5726C0.524114 15.499 0.00193898 16.7553 0 18.0658V35.9654C0.0010579 37.2765 0.522844 38.5336 1.4508 39.4607C2.37875 40.3878 3.63702 40.9091 4.94934 40.9102H28.3265C29.6383 40.909 30.896 40.3879 31.8237 39.4614C32.7514 38.5349 33.2734 37.2786 33.2752 35.968V18.0658C33.2741 16.7547 32.7524 15.4976 31.8244 14.5705C30.8965 13.6434 29.6382 13.1221 28.3259 13.121M29.7779 35.9654C29.777 36.3523 29.6228 36.7232 29.3489 36.9968C29.0751 37.2704 28.7039 37.4245 28.3165 37.4254H4.94002C4.55271 37.4245 4.18152 37.2704 3.90765 36.9968C3.63378 36.7232 3.47953 36.3523 3.47865 35.9654V18.0658C3.48023 17.6793 3.63479 17.3092 3.90858 17.0361C4.18238 16.7631 4.55317 16.6093 4.94002 16.6085H28.3172C28.7039 16.6095 29.0746 16.7633 29.3482 17.0364C29.6219 17.3094 29.7763 17.6795 29.7779 18.0658V35.9654Z" fill="white" />
        <path className="primary-col" d="M25.4857 22.4705C25.5083 22.7213 25.527 22.9681 25.527 23.2022C25.527 27.7546 21.5364 31.4636 16.6283 31.4636C11.7202 31.4636 7.73427 27.7519 7.73427 23.2002C7.7348 22.9558 7.74858 22.7115 7.77554 22.4686H6.15905C6.14108 22.7193 6.12244 22.9614 6.12244 23.2002C6.12244 28.5781 10.8341 32.9529 16.6283 32.9529C22.4225 32.9529 27.1342 28.5781 27.1342 23.2002C27.1342 22.9628 27.1202 22.7213 27.0975 22.4686H25.4817L25.4857 22.4705Z" fill="white" />
        <path className="secondary-col" d="M23.9751 16.6091C25.536 14.7727 26.3907 12.4406 26.3858 10.0313C26.4193 7.40953 25.411 4.88141 23.582 3.00106C21.7529 1.12071 19.2524 0.0415124 16.6283 0C14.0043 0.0416866 11.5039 1.12096 9.67502 3.00129C7.84614 4.88162 6.83795 7.40964 6.87141 10.0313C6.86648 12.4406 7.72121 14.7727 9.28217 16.6091H23.9751ZM16.6283 4.82642C17.9716 4.86644 19.2443 5.43666 20.1677 6.4122C21.0911 7.38774 21.5899 8.6891 21.555 10.0313C21.5899 11.3736 21.0911 12.6749 20.1677 13.6505C19.2443 14.626 17.9716 15.1962 16.6283 15.2362C15.285 15.1962 14.0123 14.626 13.0889 13.6505C12.1655 12.6749 11.6666 11.3736 11.7016 10.0313C11.6666 8.6891 12.1655 7.38774 13.0889 6.4122C14.0123 5.43666 15.285 4.86644 16.6283 4.82642Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_54_32">
          <rect width="157" height="41" fill="white" />
        </clipPath>
      </defs>
    </svg>

  )
};

export default LogoInner

