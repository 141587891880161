import React, { useState } from 'react'
import './Terms.scss'
import { Col } from 'react-bootstrap';
import * as Constants from '../utils/constants';

const PrivacyPolicy = () => {
  return (
    <Col className='terms-condition'>
      <h3 className='mb-4'>AppVentures LLC</h3>
      <h3>Privacy Policy</h3>
      <p>Updated: <strong>{Constants.EFFECTIVE_DATE}</strong></p>     
      <p>AppVentures LLC (including its affiliates and subsidiaries) (together, <strong>“shopvana,” “we,” “our,” </strong>and <strong>“us”</strong>) is committed to protecting your privacy. This privacy policy (<strong>“Privacy Policy”</strong>) applies to our application, www.shopvana.com, and any Web site (as well as any features, widgets, plug-ins, content, downloads or other services) that posts a link to this Privacy Policy (collectively, the <strong>“Services”</strong>), regardless of how you access or use them, whether via personal computer, browser, laptop, tablet, mobile phone or other device (each a <strong>“Device”</strong>). This Privacy Policy covers how shopvana collects, uses, shares, and processes personal and other information and data of users of our Services. To the extent that we provide you with notice through our Services of different or additional privacy policies or practices (e.g., at the point of collection), those additional privacy policies shall govern such data collection and use.</p>
      <p>By using or accessing our Services or by submitting information to shopvana, you consent to the terms of this Privacy Policy.</p>
      <p>In addition to reading this Privacy Policy, please review our Terms of Use (<strong>“Terms”</strong>), which govern your use of our Services. If you do not agree to our Terms and the collection, use and sharing of your information as detailed in this Privacy Policy, please do not access or otherwise use our Services or any information or content accessible on our Services.</p>
      <p>We reserve the right to change this Privacy Policy at any time, and we will post a notice or inform you of any such changes on the Services. Your use of our Services following the posting of an updated Privacy Policy constitutes your acceptance of such updated Privacy Policy. We reserve the right to apply the amended terms to the information that we have already collected, subject to any legal constraints. We will not, however, use your previously collected Personal Information (defined below) in a manner materially different than represented at the time of collection. To the extent any provision of this Privacy Policy is found by a competent tribunal to be invalid or unenforceable, such provision shall be severed to the extent necessary for the remainder to be valid and enforceable.</p>
      <p>Please note that this Privacy Policy applies only to our information-gathering, use, and dissemination practices in connection with our Services. This Privacy Policy does not apply to any of our information practices conducted separately by shopvana outside of our Services.</p>
      <h3>Personal Information Collected</h3>
      <p>We collect information from you in several ways, including when you choose to share information with us by entering it through our Services and by using automated processes. We also collect information about your transactions with us, such as any information you submit to us through our Services. For example, we collect your contact information when you subscribe to a newsletter or to pose a question to us through our “contact us” page. </p>
      <p>Some of the information we collect identifies you personally (<strong>“Personal Information”</strong>), such as your first and last name, email address, phone number, account login details, zip code, precise location information, records of communications through our Services, preferences information related to your interaction with our Services, media you upload to us, payment information including financial account numbers, mailing and billing addresses related to services we offer.</p>
      <h3>Automated Information Collection </h3>
      <p>In addition to any information that you choose to submit to us via our Services, we and our third-party service providers may use a variety of technologies that automatically (or passively) store or collect certain information whenever you visit or interact with our Services (<strong>“Usage Information”</strong>). This Usage Information may be stored or accessed using a variety of technologies that may be downloaded to your Device whenever you visit or interact with our Services. To the extent we associate Usage Information with your Personal Information we collect directly from you through our Services, we will treat it as Personal Information.</p>
      <p>This Usage Information may include:</p>
      <ul>
        <li>your IP address, UDID or other unique identifier (<strong>“Device Identifier”</strong>). A Device Identifier is a number that is automatically assigned to your Device used to access our Services, and our computers identify your Device by its Device Identifier;</li>
        <li>your Device functionality (including browser, operating system, hardware, mobile network information);</li>
        <li>the URL that referred you to the Services;</li>
        <li>the areas within our Services that you visit and your activities there, including remembering you and your preferences;</li>
        <li>your Device location;</li>
        <li>your Device characteristics; and</li>
        <li>certain other Device data, including the time of day, among other information.</li>
      </ul>
      <p><strong>Tracking Technologies</strong>. We may use various methods and technologies to store or collect Usage Information (<strong>“Tracking Technologies”</strong>). Tracking Technologies may set, change, alter or modify settings or configurations on your Device. A few of the Tracking Technologies include, without limitation, the following (and subsequent technology and methods later developed):</p>
      <ul>
        <li><strong>Cookies.</strong> A cookie is a data file placed on a Device when it is used to visit our Services. HTML5 cookies can be programmed through HTML5 local storage.</li>
        <li><strong>Web Beacons.</strong> Small graphic images or other web programming code called web beacons (also known as “1×1 GIFs” or “clear GIFs”) may be included in our Services’ pages and messages. Web beacons may be invisible to you, but any electronic image or other web programming code inserted into a page or e-mail can act as a web beacon. Web beacons or similar technologies may be used for a number of purposes, including, without limitation, to count visitors to our Services, to monitor how users navigate our Services, to count how many e-mails that were sent were actually opened or to count how many particular articles or links were actually viewed.</li>
        <li><strong>Embedded Scripts.</strong> An embedded script is programming code that is designed to collect information about your interactions with our Services, such as the links you click on. The code is temporarily downloaded onto your Device from our web server or a third-party service provider, is active only while you are connected to our Services and is deactivated or deleted thereafter.</li>
        <li><strong>Browser Fingerprinting.</strong> Collection and analysis of information from your Device, such as, without limitation, your operating system, plug-ins, system fonts and other data, for purposes of identification.</li>
        <li><strong>ETag, or Entity Tag.</strong> A feature of the cache in browsers. It is an opaque identifier assigned by a web server to a specific version of a resource found at a URL. If the resource content at that URL ever changes, a new and different ETag is assigned. Used in this manner ETags are a form of Device Identifier. ETag tracking may generate unique tracking values even where the consumer blocks HTTP, and/or HTML5 cookies.</li>
        <li><strong>Recognition Technologies.</strong> Technologies, including application of statistical probability to data sets, which attempt to recognize or make assumptions about users and devices (e.g., that a user of multiple devices in the same user).</li>        
      </ul>
      <p><strong>Tracking Technologies Usage.</strong> We may use Tracking Technologies for a variety of purposes, including: </p>
      <ul>
        <li><strong>Strictly Necessary.</strong> We may use cookies or other Tracking Technologies that we consider are strictly necessary to allow you to use and access our Services, including cookies required to prevent fraudulent activity and improve security.</li>
        <li><strong>Performance-Related.</strong> We may use cookies or other Tracking Technologies that are useful in order to assess the performance of our Services, including as part of our analytic practices or otherwise to improve the content, products or services offered through our Services.</li>
        <li><strong>Functionality-Related.</strong> We may use cookies or other Tracking Technologies that are required to offer you enhanced functionality when accessing our Services, including identifying you when you sign-in to our Services or keeping track of our specified preferences, including in terms of the presentation of content on our Services.</li>
        <li><strong>Targeting-Related.</strong> We may use Tracking Technologies to deliver content relevant to your interests on our Services and third-party sites based on how you interact with our content. This includes using Tracking Technologies to understand the usefulness to you of the content that has been delivered to you.</li>      
      </ul>
      <p>You may be able to set your browser settings to use our Services without cookie functionality. Please note that if you block cookies, some functions and Services may be unavailable and we will not be able to present personally-tailored content and advertisements to you. Your browser may provide you with some options regarding cookies. For more detailed information about how to disable or administer your cookie settings in your web browser, please refer to the applicable page for the browser you are using:</p>
      <p>
        Safari: <a href='http://help.apple.com/safari/mac/8.0/#/sfri11471' target='_blank'>http://help.apple.com/safari/mac/8.0/#/sfri11471</a><br/>
        Google Chrome: <a href='https://support.google.com/chrome/answer/95647?hl=en' target='_blank'>https://support.google.com/chrome/answer/95647?hl=en</a><br/>
        Microsoft Edge / Internet Explorer: <a href='http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies' target='_blank'>http://windows.microsoft.com/en-GB/windows-vista/Block-or-allow-cookies</a><br/>
        Firefox: <a href='https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences' target='_blank'>https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences</a>
      </p>
      <p>You can withdraw your consent to allow for cookies, change your browser settings, and delete the cookies already stored on your computer at any time. Please note that if you delete, or choose not to accept, cookies, you may not be able to utilize the features of the Services to their fullest potential.</p>
      <p><strong><u>Turning off third-party cookies</u></strong></p>
      <p>You can turn off certain third-party targeting/advertising cookies by visiting the Network Advertising Initiative: <a href="https://optout.networkadvertising.org/?c=1" target="_blank">https://optout.networkadvertising.org/?c=1</a> and by visiting Google: <a href="https://tools.google.com/dlpage/gaoptout" target="_blank">https://tools.google.com/dlpage/gaoptout</a>.</p>
      <h3>Use of Collected Information</h3>
      <p>We may use the information that we collect from you, including Personal Information, to process transactions, and provide the Services, products, services, and information to you.  We may use your information to process your requests and to provide you with information that may be of interest to you.  This includes using your precise geolocation information to recommend products and services to you that may interest you.  We may anonymize or aggregate your information for marketing and actuarial purposes. We may also use your information to process your account registration and to enable you to use certain Services features. We may also use the information that we collect to customize your experience on our Services, to better tailor our product offerings, to improve our Services and the content provided on and through our Services, to provide customer support, to enforce our Terms, to meet our legal obligations or to inform a legal proceeding, and to provide you with content or advertisements that may be of interest to you. We may also use the information we collect from you for additional purposes with your consent.</p>
      <h3>Sharing of Collected Information </h3>
      <p>We may share the information that we collect from you, including Personal Information, with third parties for a variety of purposes. For example, we may share information with service providers who assist us in our business operations. We may also share information where required by law or to satisfy any applicable law, regulation, subpoena, government request, or other legal process. We may also share information with third parties, including law enforcement, to protect our Services, to fight fraud and identity theft, to ensure the integrity and operation of our organization and systems, to protect the rights, property or safety of shopvana, its employees, members or others, and to enforce our Privacy Policy and Terms. We also reserve the right to share the information that we collect with our subsidiaries and affiliates for marketing and other business purposes and with any subsequent owner in the event of a merger, consolidation, sale of our assets, or other change in our business, including during the course of any due diligence process.</p>
      <p>Occasionally, shopvana may conduct marketing surveys or research to help us evaluate products, Services, and the changing needs of our customers. </p>
      <p>When we share your Personal Information with third parties for business purposes, we take reasonable steps to ensure that these providers do not use or otherwise disclose any Personal Information we collect about you except for the purpose of fulfilling their service obligations to us.</p>
      <p>We may share aggregate information about Services users with certain third parties. This information shows user activity as a whole rather than on an individual basis; such aggregate information cannot be used to individually identify you. We may use such aggregated information we collect about Services users to continue to improve our Services for you.</p>
      <p>As permitted by law, we may disclose Personal Information, which may or may not include to a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization, dissolution or other sale or transfer of some or all of shopvana assets, whether as a going concern or as part of bankruptcy, liquidation or similar proceeding, in which such information is among the assets transferred. The acquiring entity will be subject to this Privacy Policy and to the law in its use of your information, including any possible use of your Personal Information to the extent that any such information is transferred.</p>
      <h3>Other Web sites and Links</h3>
      <p>The Services may contain links to other Web sites and services that are not operated by shopvana. Please note that when you click on a link to one of these Web sites, you are exiting our Services to go to another provider. Shopvana is not responsible for the content or privacy practices of these other services or Web sites. We suggest that you read the privacy policies of these providers, as their practices and policies may differ from ours.</p>
      <p>We may provide certain parts of our Services in association with third parties, such as promotional partners or affiliates. These “co-branded areas” will identify the third party. If you choose to use these co-branded areas, we may share your information with the identified third party, and that third party may also collect information from you, in addition to the information that we collect, as described above. You should review the privacy policies of those identified third parties to understand how they collect and use information.</p>
      <h3>Analytic Services and Targeted Ads</h3>
      <p>We use third-party analytics services, like Google Analytics, to collect, monitor and analyze data we collect through our Services to, for example better understand how our Services are used and to improve their functionality.  These third-party analytics services may track details about your online activities over time and across different Web sites.  These services may also allow us and others to provide you with targeted advertisements or other content that you may be interested in based on your online activities.</p>
      <p>The Google Analytics service tracks and reports Web site traffic and provides us insight into behavior information relating to visitor age, gender and interests.  Google Analytics Demographics and Interest Reporting give us insight into behavior to help us understand browsing behavior and give you a better experience when you visit our Services. You can opt-out of Google Analytics for Display Advertising and customize Google Display Network ads using the Ads Settings. </p>
      <p>These third-party services have their own privacy policies addressing how they use such information. We recommend you review their privacy policies. If you want to prevent your data from being used by Google Analytics, you may wish to take advantage of Google Analytics' <a href="https://tools.google.com/dlpage/gaoptout/" target="_blank">currently available opt-outs</a> for the web.  </p>
      <p>If you would like to learn more about targeted ads that may be based on your online activities, and the choices that you may exercise for certain sites and advertisers, you may wish to visit the <a href="https://thenai.org/" target="_blank">Network Advertising Initiative</a> or the <a href="https://digitaladvertisingalliance.org/" target="_blank">Digital Advertising Alliance</a>.</p>
      <h3>Sweepstakes, Contests and Promotions</h3>
      <p>We may offer sweepstakes, contests, and other promotions (any, a <strong>“Promotion”</strong>) through the Services that may require registration.  By participating in a Promotion, you are agreeing to the official rules that govern that Promotion which are separate from this Privacy Policy, and which may contain specific requirements of you, including, allowing the sponsor of the Promotion to use your name, voice, and/or likeness in advertising or marketing associated with the Promotion.  If you choose to enter a Promotion, certain Personal Information may be disclosed to third-parties or the public in connection with the administration of such Promotion, including, in connection with winner selection, prize fulfillment, and as required by law or permitted by the Promotion’s official rules, such as on a winners list.</p>
      <h3>Our Commitment to Data Security</h3>
      <p>To prevent unauthorized access, maintain data accuracy, and ensure the correct use of Personal Information, we have put in place reasonable physical, electronic, and managerial procedures to safeguard and secure the Personal Information we collect through the Services and we make good faith efforts to store your Personal Information in a secure operating environment. However, no data transmission over the Internet, mobile networks, wireless transmission or electronic storage of information can be guaranteed 100% secure. Please note that we cannot guarantee the security of any information you transmit to us.</p>
      <h3>Using our Services for Purchases</h3>
      <p>In order to make purchases on the Services, you may need to go through our checkout process and provide your payment information, such as your credit card number and expiration date.  If you choose to make a payment online, your payment will be processed by our PCI-compliant third-party payment processor, Stripe.  Shopvana strives to ensure that all of its partners and affiliates maintain industry-standard privacy policies and practices.  Please be aware, however, that shopvana does not directly collect or store any of your payment information, and it does not control the privacy policies and practices of its third-party payment processors.  To understand how a third-party payment processor collects, processes and stores your payment and other information, please review the privacy policy of our payment processors.  The Privacy Policy for Stripe can be found here: <a href="https://stripe.com/privacy" target="_blank">https://stripe.com/privacy</a>.</p>
      <h3>Your California Privacy Rights</h3>
      <p>Residents of California may also, under §1798.83, known as the “Shine The Light” law, request and obtain from us, once a year and free of charge, information about categories of Personal Information (if any) we disclosed to third parties for direct marketing purposes and the names and addresses of all third parties with which we shared Personal Information in the immediately preceding calendar year.  If you are a California resident and would like to make such a request, please submit your request clearly in writing to us using the contact information provided below.</p>
      <h3>Users Outside of the United States.</h3>
      <p>If you are from a non-U.S. country, please be aware that the Personal Information you submit, including information provided through our Services, is being sent to a location in the United States. The data protection laws in the United States are likely different from those of the country in which you are located, and your Personal Information may be subject to access requests from governments, courts, or law enforcement in the United States according to laws of the United States. By providing such Personal Information through the Services, you are consenting to (and represent that you have authority to consent to), the transfer of such information to the United States for the uses and purposes described in this Privacy Policy. Without limiting the above, for the avoidance of doubt we do not have an establishment or target people in the EEA or UK.</p>
      <h3>Children’s Privacy</h3>
      <p>The Services are not intended to be used by children and are not directed toward children under the age of 13. Shopvana does not use the Services to knowingly solicit data from or market to children under the age of 13. The Services do not sell products for purchase by children. No person under 13 years of age should disclose information on the Services. If you are under 18, you may use the Services only with the involvement of a parent or guardian.</p>
      <p>Please visit <a href="https://consumer.ftc.gov/identity-theft-and-online-security/protecting-kids-online" target="_blank">www.consumer.ftc.gov/topics/kids-online-safety</a> to learn more about protecting children privacy.</p>
      <h3>How to Contact Us</h3>
      <p>We appreciate your questions or comments about this Privacy Policy, our Services, or your dealings with shopvana. Please contact us by e-mail at <a href="mailto:admin@shopvana.com">admin@shopvana.com</a> or by using the mailing information below:</p>
      <p>
        <strong>31 HomeDepot Drive, Plymouth Ma. 02360, Box 235</strong>{' '}
      </p>
    </Col>
  )
};

export default PrivacyPolicy;

