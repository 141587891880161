/**
 * @file   src\containers\Signup.tsx
 * @brief  Signup page.
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import { useEffect, useState } from 'react';
import { Container, Row, Col, Button, Form, Modal } from 'react-bootstrap';
import '../assets/styles/prelogin.scss';
import Strings from '../assets/strings/Strings.json';
import Logo from '../assets/img/logo-prelogin.svg';
import NBInputGroup from '../components/NBInputGroup';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import NBInputGroupPswd from '../components/NBInputGroupPswd';
import NBPhoneInput from '../components/NBPhoneInputGroup';
import NBOtpInput from '../components/NBOtpInput';
import { emailValidation, passwordValidation, validatePhoneNo, nameValidation, isOTPValid } from '../utils/authHelper';
import { useAppDispatch, useAppSelector } from '../hooks/index';
import { toast } from 'react-toastify';
import { signup, sendcode, verifyCode } from '../store/actions/authAction';
import { resetSignupState, resetVerifyOtpState, resetSendOTPState } from '../store/slices/authSlice';
import * as alerts from '../utils/alerts';
import * as CONSTANT from '../utils/constants';
import { setUser } from '../utils/localStorage';
import TermsConditions from '../components/Terms';
import PrivacyPolicy from '../components/Privacy';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { IsignupData, IsignupDataError, IuserEmailData, Icode, IcodeError } from '../interfaces/GeneralInterface';
import { removeGuestUser } from '../utils/localStorage';
const SignUp = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showTermsAndConditionModal, setShowTermsAndConditionModal] = useState<boolean>(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState<boolean>(false);
  const [show, setShow] = useState<boolean>(false);
  const signUpResponseData = useAppSelector((RootReducer) => RootReducer.auth.signup);
  const requestOTPResponseData = useAppSelector((RootReducer) => RootReducer.auth.sendOTP);
  const verifyCodeResponseData = useAppSelector((RootReducer) => RootReducer.auth.verifyOTP);
  const [isChecked, setIsChecked] = useState<boolean>(false);
  const [signupUserInfo, setSignupUserInfo] = useState<IuserEmailData>({ e_address: '' });
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [signupData, setSignupData] = useState<IsignupData>({
    first_name: '',
    last_name: '',
    phone_number: '',
    password: '',
    email: '',
  });

  const [signupError, setSignupError] = useState<IsignupDataError>({
    first_name_error: '',
    last_name_error: '',
    phone_number_error: '',
    password_error: '',
    email_error: '',
  });

  const [codeInfo, setCodeInfo] = useState<Icode>({
    email_otp: '',
  });

  const [codeInfoError, setCodeInfoError] = useState<IcodeError>({
    email_otp_error: '',
  });

  // handle the remaing otp verification initialization.
  useEffect(() => {
    if (location && location.state && location.state.signin_data) {
      if (location.state.signin_data?.is_verified === 0) {
        sendResendCode(location.state.signin_data?.email);
        setSignupUserInfo({ e_address: location.state.signin_data?.email });
        setShow(true);
      }
    }
  }, []);

  // Mask Email address.
  const maskEmail = (email: string) => {
    let split = email.split('@');
    return email.substring(0, 1) + '*******' + split[0].slice(-1) + '@' + split[1];
  };

  // sign up data error validation.
  const signupValidation = () => {
    setSignupError((setSignupError) => ({ ...setSignupError, first_name_error: '', email_error: '', last_name_error: '', password_error: '', phone_number_error: '' }));
    let isValid = true;
    const firstNameErrMsg = nameValidation(signupData.first_name);
    const lastNameErrMsg = nameValidation(signupData.last_name);
    const phoneNumberErrMsg = validatePhoneNo(signupData.phone_number);
    const passwordErrorMsg = passwordValidation(signupData.password, '');
    switch (true) {
      case signupData.first_name.trim() === '':
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['first_name_error']: alerts.ENTER_FIRST_NAME,
        }));
        break;
      case firstNameErrMsg !== '':
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['first_name_error']: firstNameErrMsg,
        }));
        break;

      case signupData.last_name.trim() === '':
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['last_name_error']: alerts.ENTER_LAST_NAME,
        }));
        break;
      case lastNameErrMsg !== '':
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['last_name_error']: lastNameErrMsg,
        }));
        break;

      case signupData.email.trim() === '':
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['email_error']: alerts.ENTER_EMAIL,
        }));
        break;
      case emailValidation(signupData.email) === false:
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['email_error']: alerts.ENTER_VALID_EMAIL,
        }));
        break;
      case signupData.phone_number.trim() === '':
        isValid = false;
        setSignupError((signupStep1Error) => ({
          ...signupStep1Error,
          ['phone_number_error']: alerts.ENTER_PHONE_NUMBER,
        }));
        break;
      case phoneNumberErrMsg !== '':
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['phone_number_error']: phoneNumberErrMsg,
        }));
        break;
      case passwordErrorMsg !== '':
        isValid = false;
        setSignupError((setSignupError) => ({
          ...setSignupError,
          ['password_error']: passwordErrorMsg,
        }));
        break;
      default:
    }
    return isValid;
  };

  // handle mobile input data formatting.
  const handleMobileInputChange = (event: string) => {
    let inputValue = event;
    const validFormat = /^[-\d\(\)\[\]\s]+$/
    const validDigit = /\d+/g
    const nonDigit = /\D/
    if (validFormat.test(inputValue)) {
      const numbers: any = inputValue.match(validDigit);
      const fullNumber = numbers.join('');
      setSignupData((signupData) => ({ ...signupData, ['phone_number']: fullNumber }));
    } else if (!nonDigit.test(inputValue)) {
      setSignupData((signupData) => ({ ...signupData, ['phone_number']: event }));
    }
  };

  // handle sign up on change data.
  const handleSignupChange = (event: { target: { name: any; value: any } }) => {
    let inputValue = event.target.value;
    if (event.target.name === 'first_name' || event.target.name === 'last_name') {
      inputValue = inputValue.charAt(0).toUpperCase() + inputValue.slice(1);
      setSignupData((signupData) => ({ ...signupData, [event.target.name]: inputValue }));
    } else {
      setSignupData((signupData) => ({ ...signupData, [event.target.name]: event.target.value }));
    }
  };

  // handle terms and conditions check box.
  const handleCheckboxChange = (event: any) => {
    setIsChecked(event.target.checked);
  };

  // handle terms and conditions modal closing.
  const handleTermsAndConditionClose = () => setShowTermsAndConditionModal(false);
  // handle privacy policy modal closing
  const handlePrivacyClose = () => setShowPrivacyModal(false);

  // handle signup api call response data.
  useEffect(() => {
    if (signUpResponseData.isLoading === false && signUpResponseData.isSuccess === true) {
      if (signUpResponseData.errorCode === CONSTANT.API_SUCCESSCODE && signUpResponseData.userInfo) {
        setIsChecked(false);
        setSignupData({
          first_name: '',
          last_name: '',
          phone_number: '',
          password: '',
          email: '',
        });
        setShow(true);
        setSignupUserInfo(signUpResponseData.userInfo.user_info);
      } else {
        toast.error(signUpResponseData.errorMessage);
      }
      dispatch<any>(resetSignupState());
    }
  }, [signUpResponseData]);

  // handle phone format
  const phoneFormat = (value: any) => {
    let formattedPhoneNumber = value;
    if (value) {
      if (value.length <= 3) {
        formattedPhoneNumber = value;
      } else if (value.length > 3 && value.length <= 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)}`;
      } else if (value.length > 6) {
        formattedPhoneNumber = `(${value.slice(0, 3)}) ${value.slice(3, 6)} ${value.slice(6, 10)}`;
      }
      return formattedPhoneNumber;
    }
  };

  // handle sign up submit button and initiate sign up api call.
  const onSubmit = () => {
    const isValidInfo = signupValidation();
    if (isValidInfo) {
      const phoneNo = phoneFormat(signupData.phone_number);
      const payload = {
        email: signupData?.email,
        pwd: signupData?.password,
        mob_number: phoneNo,
        first_name: signupData.first_name,
        last_name: signupData.last_name,
      };      
      dispatch<any>(signup(payload));
    }
  };

  // handle verification code api call.
  const sendResendCode = (e_address: string) => {
    let payload = { email: e_address };
    dispatch<any>(sendcode(payload));
    setCodeInfo({
      email_otp: '', // Reset email_otp to an empty string or initial value and close the modal
    });
  };

  // handle request verification send code response data.
  useEffect(() => {
    if (requestOTPResponseData.isLoading === false && requestOTPResponseData.isSuccess === true) {
      if (requestOTPResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        setShow(true);
        toast.success(requestOTPResponseData.errorMessage);
      } else {
        toast.error(requestOTPResponseData.errorMessage);
      }
    }
    dispatch<any>(resetSendOTPState());

  }, [requestOTPResponseData]);

  // handle verification code on change data.
  const handleEmailOTPChange = (otp: any) => {
    const numberValidation = /^[0-9]*$/;
    if (numberValidation.test(otp)) {
      setCodeInfo((codeInfo) => ({ ...codeInfo, ['email_otp']: otp }));
    }
  };

  // handle verification code error validation.
  const validatOTP = (otp: any) => {
    let isValid = true;
    let isValidOTP = isOTPValid(otp);
    if (isValidOTP === false) {
      setCodeInfoError((setCodeInfoError) => ({ ...setCodeInfoError, ['email_otp_error']: alerts.INVALID_CODE }));
    } else {
      setCodeInfoError((setCodeInfoError) => ({ ...setCodeInfoError, ['email_otp_error']: '' }));
    }
    return isValid;
  };

  // handle verification code response data.
  useEffect(() => {
    if (verifyCodeResponseData.isLoading === false && verifyCodeResponseData.isSuccess === true) {
      if (verifyCodeResponseData.errorCode === CONSTANT.API_SUCCESSCODE) {
        const userInfo = {
          a_token: verifyCodeResponseData.userInfo.user_info.a_token,
          r_token: verifyCodeResponseData.userInfo.user_info.r_token,
          f_name: verifyCodeResponseData.userInfo.user_info.f_name,
          l_name: verifyCodeResponseData.userInfo.user_info.l_name,
          e_time: verifyCodeResponseData.userInfo.user_info.e_time,
          user_id: verifyCodeResponseData.userInfo.user_info.u_id,
          email: verifyCodeResponseData.userInfo.user_info.e_address,
        };
        setUser(JSON.stringify(userInfo));
        toast.success(verifyCodeResponseData.errorMessage);
        resetOTPForm();
        removeGuestUser();
        setTimeout(() => {
          navigate('/');
        }, 100);
      } else {
        toast.error(verifyCodeResponseData.errorMessage);
      }
      dispatch<any>(resetVerifyOtpState());
    }
  }, [verifyCodeResponseData]);

  // handle submit verification code button and call's verify code api. 
  const submitOTPForm = () => {
    let isValidInput = validatOTP(codeInfo.email_otp);
    if (isValidInput) {
      const payload = {
        email: signupUserInfo.e_address,
        otp: codeInfo?.email_otp,
        is_user_web: 1,
      };
      dispatch<any>(verifyCode(payload));
      setCodeInfo({
        email_otp: '', // Reset email_otp to an empty string or initial value and close the modal
      });
    }
  };

  // reset verification code data to empty and close verification code modal
  const resetOTPForm = () => {
    setCodeInfo({
      email_otp: '', // Reset email_otp to an empty string or initial value and close the modal
    });
    setShow(false);
  };
  // handle verification code cancel button
  const handleCancel = () => {
    setCodeInfo({
      email_otp: '', // Reset email_otp to an empty string or initial value and close the modal
    });
    setShow(false);
    setCodeInfoError((setCodeInfoError) => ({ ...setCodeInfoError, ['email_otp_error']: '' }));
    navigate('/signin');
  };

  // unmask the password
  const handleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  return (
    <Container fluid className="pre-login">
      <Row>
        <Col lg="6">
          <div className="nb-caption d-flex align-items-center justify-content-center h-100">
            <p className="text-center">shopvana. Where your wish list comes true.</p>
          </div>
        </Col>
        <Col lg="6" className="d-flex align-items-center justify-content-center">
          <div className="nb-login">
            <img src={Logo} alt="" />
            <h1>{Strings.SignUp.Title}</h1>
            <NBInputGroup
              id="firstname"
              name="first_name"
              type="text"
              maxLength="15"
              label={Strings.SignUp.Label1}
              onChange={handleSignupChange}
              value={signupData.first_name}
              error={signupError.first_name_error}
            />
            <NBInputGroup
              id="lastname"
              name="last_name"
              type="text"
              maxLength="15"
              label={Strings.SignUp.Label2}
              onChange={handleSignupChange}
              value={signupData.last_name}
              error={signupError.last_name_error}
            />
            <NBInputGroup
              id="email"
              name="email"
              type="email"
              maxLength="50"
              label={Strings.SignUp.Label3}
              onChange={handleSignupChange}
              value={signupData.email}
              error={signupError.email_error}
            />
            <NBPhoneInput
              id="phone"
              name="phone_number"
              type="number"
              maxLength="14"
              label={Strings.SignUp.Label5}
              onChange={handleMobileInputChange}
              value={signupData.phone_number}
              error={signupError.phone_number_error}
            />
            <NBInputGroupPswd
              id="password"
              name="password"
              type="text"
              maxLength="15"
              label={Strings.Login.Label2}
              onChange={handleSignupChange}
              value={signupData.password}
              error={signupError.password_error}
              showpass={showPassword}
              handleShowPassword={handleShowPassword}
            />
            <div className="agree d-flex align-items-center">
              <Form.Check name="agree" checked={isChecked} onChange={handleCheckboxChange} />
              {Strings.SignUp.AgreeTxt}&nbsp;
              <Link onClick={() => setShowTermsAndConditionModal(true)} to="">
                {' '}
                <span>{Strings.SignUp.AgreeLink}&nbsp;</span>
              </Link>
              <Link onClick={() => setShowPrivacyModal(true)} to="">
                <span>{Strings.SignUp.AgreeLinkPrivacy}</span>
              </Link>
            </div>
            <Button variant="primary" className="w-100 mb-4" disabled={!isChecked} onClick={onSubmit}>
              {Strings.SignUp.Btn}
            </Button>
            <div className="guest text-center">
              <Link to="/">{Strings.SignUp.Btn2}</Link>
            </div>
          </div>
        </Col>
      </Row>
      {/* OTP Modal */}
      <Modal show={show} centered backdrop="static">
        <Modal.Body className="otp-verify">
          <h1 className="text-center">{Strings.SignUp.OtpModal.Title}</h1>
          <p className="text-center">
            {Strings.SignUp.OtpModal.InfoTxt}
            <br />
            <span>{maskEmail(signupUserInfo.e_address)}</span>
          </p>
          <div className="otp-wrap d-flex justify-content-center">
            <NBOtpInput
              name="otp"
              id="otp"
              type="number"
              label={Strings.SignUp.OtpModal.Label1}
              linkName={Strings.SignUp.OtpModal.LinkTxt}
              onChange={handleEmailOTPChange}
              value={codeInfo.email_otp}
              resendOtp={() => sendResendCode(signupUserInfo.e_address)}
            />
          </div>
          <div className="btn-wrap d-flex">
            <Button variant="secondary" onClick={handleCancel}>
              {Strings.SignUp.OtpModal.Btn}
            </Button>
            <Button variant="primary" onClick={submitOTPForm}>
              {Strings.SignUp.OtpModal.Btn2}
            </Button>
          </div>
        </Modal.Body>
      </Modal>
      <Modal show={showTermsAndConditionModal} onHide={handleTermsAndConditionClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Terms.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={400} >
            <TermsConditions />
          </Scrollbars>
        </Modal.Body>
      </Modal>
      <Modal show={showPrivacyModal} onHide={handlePrivacyClose} size="xl" centered>
        <Modal.Header closeButton>
          <Modal.Title>{Strings.Privacy.Title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Scrollbars autoHeight autoHeightMin={0} autoHeightMax={400} >
            <PrivacyPolicy />
          </Scrollbars>
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default SignUp;
