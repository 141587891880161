/**
 * @file   src\containers\Support.tsx
 * @brief  Support page
 * @date   Sep, 2023
 * @author ZCO Engineer
 * @copyright (c) 2023, ZCO
 */
import React from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import '../assets/styles/vendordetails.scss';
import Strings from '../assets/strings/Strings.json';
import NBInputGroup from '../components/NBInputGroup';
import NBPhoneInput from '../components/NBPhoneInputGroup';
import { IsupportData, IsupportDataError } from '../interfaces/user';
import { emailValidation, nameValidation, validatePhoneNo } from '../utils/authHelper';
import * as alerts from '../utils/alerts';
import { useDispatch } from 'react-redux';
import { userSupport } from '../store/actions/userAction';
import * as CONSTANT from '../utils/constants';
import { useAppSelector } from '../hooks/index';
import { toast } from 'react-toastify';
import { resetUserSupportState } from '../store/slices/userSlice';

const Support = () => {
  const dispatch = useDispatch();
  const currentUrl = window.location.href;
  const newUser = currentUrl.split('?new_user=')[1];
  const intitalValues = {
    name: '',
    email: '',
    Phone: '',
    Message: '',
  };

  const intitalValuesError = {
    nameError: '',
    emailError: '',
    PhoneError: '',
    MessageError: '',
  };
  const [supportData, setSupportData] = React.useState<IsupportData>(intitalValues);
  const [supportDataError, setSupportDataError] = React.useState<IsupportDataError>(intitalValuesError);

  const _userSupport = (params: any) => {
    dispatch<any>(userSupport(params));
  };
  const userSupportResponse = useAppSelector((RootReducer) => RootReducer.user.userSupport);

  React.useEffect(() => {
    // Include Zendesk Web Widget script dynamically
    const script = document.createElement('script');
    script.src = 'https://static.zdassets.com/ekr/snippet.js?key=9bf5de41-7b28-4f4c-b165-7114ffab8b15';
    script.id = 'ze-snippet';
    script.async = true;
    document.head.appendChild(script);
   
    return () => {
      // Cleanup when the component is unmounted
      document.head.removeChild(script);
    };
  }, []);

  React.useEffect(() => {
    if (userSupportResponse.isLoading === false && userSupportResponse.isSuccess === true) {
      if (userSupportResponse.errorCode === CONSTANT.API_SUCCESSCODE) {
        toast.success(userSupportResponse?.errorMessage);
        setSupportData(intitalValues);
      }
      dispatch(resetUserSupportState());
    }
  }, [userSupportResponse]);

  React.useEffect(() => {
    if (newUser === 'true') {
      window.localStorage.clear();
    }
  }, [newUser]);

  // handles password changes
  const handleChanges = (event: { target: { name: any; value: string } }) => {
    setSupportData(() => ({
      ...supportData,
      [event.target.name]: event.target.value,
    }));
  };

  // handle phone change data
  const handlePhoneChanges = (event: string) => {
    setSupportData(() => ({
      ...supportData,
      Phone: event,
    }));
  };

  const validate = () => {
    let isValid = true;
    const validEmail = emailValidation(supportData.email);
    const firstNameErrMsg = nameValidation(supportData.name);
    const phoneNumberErrMsg = validatePhoneNo(supportData.Phone);

    if (supportData.name.trim() === '') {
      setSupportDataError((supportDataError) => ({ ...supportDataError, nameError: alerts.ENTER_FIRST_NAME }));
      isValid = false;
    } else if (firstNameErrMsg) {
      setSupportDataError((supportDataError) => ({ ...supportDataError, nameError: firstNameErrMsg }));
      isValid = false;
    } else {
      setSupportDataError((supportDataError) => ({ ...supportDataError, nameError: '' }));
    }

    if (supportData.Message.trim() === '') {
      setSupportDataError((supportDataError) => ({ ...supportDataError, MessageError: alerts.ENTER_MESSSAGE }));
      isValid = false;
    } else {
      setSupportDataError((supportDataError) => ({ ...supportDataError, MessageError: '' }));
    }
    if (supportData.Phone === '') {
      setSupportDataError((supportDataError) => ({ ...supportDataError, PhoneError: alerts.ENTER_PHONE_NUMBER }));
      isValid = false;
    } else if (phoneNumberErrMsg !== '') {
      setSupportDataError((supportDataError) => ({ ...supportDataError, PhoneError: phoneNumberErrMsg }));
      isValid = false;
    } else {
      setSupportDataError((supportDataError) => ({ ...supportDataError, PhoneError: '' }));
    }

    if (supportData.email.trim() === '') {
      setSupportDataError((supportDataError) => ({ ...supportDataError, emailError: alerts.ENTER_EMAIL }));
      isValid = false;
    } else if (!validEmail) {
      setSupportDataError((supportDataError) => ({ ...supportDataError, emailError: alerts.ENTER_VALID_EMAIL }));
      isValid = false;
    } else {
      setSupportDataError((supportDataError) => ({ ...supportDataError, emailError: '' }));
    }
    return isValid;
  };

  const handleSubmit = () => {
    const valid = validate();
    const payload = {
      name: supportData.name,
      email: supportData.email,
      phone_no: supportData.Phone,
      message: supportData.Message,
    };
    if (valid) {
      _userSupport(payload)
    }
  };
  // handle to copy the number to clipboard
  const handleCopyPhoneNo = async () => {
    // Check if there is an extracted number
      try {
        await navigator.clipboard.writeText('3238462000');
        toast.success('Number copied to clipboard');
      } catch (err) {
        console.error('Failed to copy number to clipboard', err);
      }
  };
  const handlemail = () => {
    window.open('mailto:admin@shopvana.com', '_blank');
  };
  return (
    <div className="inner-layout">
      <Container fluid className="page-header">
        <Container>
          <Row>
            <Col md="6" className="d-flex align-items-center">
              <h2>{Strings.Support.Title}</h2>
            </Col>
          </Row>
        </Container>
      </Container>
      <Container fluid>
        <Container className="content-area">
          <Row>
            <Col lg="8" className="product-outer">
              <h3>{Strings.Support.Subhead}</h3>
              <Row>
                <Col lg="10">
                  <NBInputGroup
                    onChange={handleChanges}
                    value={supportData.name}
                    error={supportDataError.nameError}
                    id="name"
                    type="text"
                    name="name"
                    label={Strings.Support.Label1}
                    maxLength={50}
                  />
                </Col>
                <Col lg="5">
                  <NBInputGroup
                    onChange={handleChanges}
                    value={supportData.email}
                    error={supportDataError.emailError}
                    id="email"
                    type="email"
                    name="email"
                    label={Strings.Support.Label2}
                    maxLength={150}
                  />
                </Col>
                <Col lg="5">
                  <NBPhoneInput
                    onChange={handlePhoneChanges}
                    value={supportData.Phone}
                    error={supportDataError.PhoneError}
                    id="Phone"
                    type="number"
                    name="Phone"
                    label={Strings.Support.Label3}
                  />
                </Col>
                <Col lg="10">
                  <NBInputGroup
                    onChange={handleChanges}
                    value={supportData.Message}
                    error={supportDataError.MessageError}
                    id="Message"
                    as="textarea"
                    name="Message"
                    rows={5}
                    label={Strings.Support.Label4}
                    maxLength={500}
                  />
                </Col>
              </Row>
              <Button onClick={handleSubmit} variant="primary">
                {Strings.Support.Btn}
              </Button>
            </Col>
            <Col lg="4">
              <div className="contact-vendor">
                <div className="nb-address">
                  <h3 className="pt-0">{Strings.AccountDelete.Subhead2}</h3>
                  <p>
                    31 HomeDepot Drive
                    <br />
                    Plymouth Ma 02360, Box 235
                  </p>
                </div>
                <div className="nb-customercare">
                  <h3>{Strings.AccountDelete.Subhead3}</h3>
                  <p onClick={handlemail} className="message">{Strings.AccountDelete.Email}</p>
                  <p onClick={handleCopyPhoneNo} className="phone">{Strings.AccountDelete.Phone}</p>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};

export default Support;
